<template>
  <div>
    <h1 class="font-size-large text-color5">
      <span class="fw-700">Termos de Uso e Política de Privacidade</span>
    </h1>
    <p>A empresa <i>ADSPLAY X</i> LTDA., detentora dos direitos da plataforma <i>Pixel Roads</i>, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 29.835.275/0001-27, doravante denominada simplesmente “<i>Pixel Roads</i>”, e, de outro lado, o cliente qualificado no momento da compra dos produtos, doravante denominado simplesmente “CLIENTE”. “<i>Pixel Roads</i>” e o “CLIENTE” são denominados conjuntamente “Partes”;</p>
    <p><i>Pixel Roads</i>  é um software de serviços que conecta sua marca com seus consumidores a fim de entregar a melhor experiência com mídia programática para ambos. Em nosso software é possível criar campanhas personalizadas de acordo com o público alvo, ajudando seu negócio atingir potenciais clientes. Nossos algoritmos utilizam dados  e tecnologias de terceiros, de acordo com a LGDP, anônimos em larga escala para entender interesses e prever comportamentos on-line.</p>
    <p>Considerando que:</p>
    <ul>
      <li>A <i>Pixel Roads</i> é um software de serviços (SaaS) que conecta uma empresa aos seus potenciais consumidores através de mídia programática;</li>
      <li>A <i>Pixel Roads</i> é um software de serviços (SaaS) que conecta uma empresa aos seus potenciais consumidores através de mídia programática;</li>
      <li>O CLIENTE possui interesse na compra dos serviços oferecidos pela <i>Pixel Roads</i>, indicados através de seu website e em seus canais de vendas.</li>
    </ul>
    <p>Compreendendo a importância da privacidade dos usuários, em nossa rede de publicidade e através de parcerias, estamos comprometidos com sua segurança e proteção total dos seus dados, prezamos por seguir com rigor todas as orientações regidas pela LGPD - Lei Geral de Proteção de Dados (Lei n. 13.709/2018).</p>

    <h2>1. O objeto deste termo de uso:</h2>
    <p>1.1. Este Termo se aplica para regular o uso dos serviços oferecidos pela <i>Pixel Roads</i> ao CLIENTE, sendo estes a criação de campanhas online personalizadas, com seleção de público-alvo e objetivos de performance, para alcançar potenciais consumidores.</p>

    <h2>2. Para fins destes Termos e Condições de Compra, aplicam-se as seguintes definições:</h2>
    <p><i>Pixel Roads</i>: Plataforma de compra de mídia programática, situada no endereço eletrônico adsplayx.com.br</p>
    <p>Cadastro: Ato de o visitante inserir seus dados para se cadastrar na plataforma e ter acesso às áreas e funcionalidades restritas a clientes.</p>
    <p>Cliente(s): Agências de mídia, publicidade, marketing, ou marcas que efetuaram o cadastro e o login na plataforma.</p>
    <p>ID Number: número único e intransferível para cada CNPJ ou CPF cadastrado na plataforma. </p>
    <p>Usuário: Todo visitante autenticado no endereço eletrônico da <i>Pixel Roads</i>.</p>
    <p>Visitante: Qualquer pessoa que navegar no endereço eletrônico da <i>Pixel Roads</i>.</p>
    <p>O CLIENTE deve estar ciente de que, ao fazer seus pedidos pelo website da <i>Pixel Roads</i>, estará, automaticamente, concordando com os Termos e Condições de Uso. A recusa na aceitação destes Termos e Condições de Uso impedirá que você faça pedidos de serviços e campanhas em nosso website.</p>

    <h2>3. Os termos do cadastro:</h2>
    <p><strong>Resumo:</strong> Ao se cadastrar na <i>Pixel Roads</i> o usuário se responsabiliza pela veracidade das informações e se compromete em cadastrá-los corretamente.</p>
    <p>O CLIENTE, para utilizar os serviços descritos, deverá fornecer as informações requisitadas no CADASTRO, assumindo integralmente a responsabilidade, cível e criminal, pela exatidão e veracidade das informações prestadas, que poderão ser verificadas, a qualquer momento, através do website da <i>Pixel Roads</i>.</p>
    <p>Na hipótese de informações incorretas, inverídicas ou não confirmadas, bem como na hipótese de negativa em corrigi-las ou enviar a documentação que comprove a correção, a <i>Pixel Roads</i> se reserva no direito de não concluir o cadastramento em curso, ou, ainda, de bloquear o cadastro já existente, impedindo o CLIENTE de utilizar os serviços on-line até que, a critério da <i>Pixel Roads</i>, a situação já esteja regularizada.</p>
    <p>A <i>Pixel Roads</i> se reserva no direito de impedir, a seu critério, novos CADASTROS, ou cancelar os já efetuados, em caso de ser detectadas irregularidades e ilegalidades que, em seu critério, seja revestida de gravidade ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigatórias para seus CLIENTES. Com o mesmo procedimento, agirá a <i>Pixel Roads</i>, caso constate o descumprimento pelo CLIENTE, de qualquer obrigação prevista no presente Termo.</p>
    <p>Efetuado, com sucesso, o CADASTRO, o CLIENTE terá acesso aos serviços por meio de login e senha, dados estes que se compromete a não divulgar a terceiros, ficando sob sua exclusiva responsabilidade qualquer solicitação de serviço que seja feita com o uso do login e senha de sua titularidade.</p>
    <p>A <i>Pixel Roads</i> apenas solicita informações pessoais quando as mesmas são essenciais para o fornecimento do serviço escolhido pelo CLIENTE. Tais solicitações são feitas por meios justos e legais, com o conhecimento e consentimento obrigatório por parte do CLIENTE.</p>
    <p>Na hipótese de armazenamento de dados do CLIENTE, a <i>Pixel Roads</i> compromete-se a realizar a proteção dos dados dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados. </p>

    <h2>4. Como utilizamos os dados inseridos em nossa plataforma no momento de cadastro:</h2>
    <p><strong>Resumo:</strong> Explicamos o uso que faremos dos dados do usuário e quais políticas utilizamos em conformidade com a LGPD.</p>
    <p>Dados fornecidos pelo Usuário, são as informações fornecidas por você ao interagir, contratar ou utilizar o serviços oferecidos pela <i>Pixel Roads</i></p>
    <p>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado. Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, os protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</p>
    <p>Para fins da exibição de anúncios de acordo com as preferências do usuário, utilizamos plataformas terceiras, que coletam e tratam dados pessoais pseudonimizados dos usuários diretamente dos sites (ou nos aplicativos para dispositivos móveis). Esse processo é realizado com algoritmos de última geração para garantir que nenhum tipo de informação pessoal identificável seja coletada;</p>
    <p>Nossos parceiros coletam dados de navegação anônimos e de região geográfica, e acompanhamos usuários através de chaves criadas com uso de cookies, endereço IP e informações anônimas do dispositivo, sendo de responsabilidade desses parceiros a coleta e a segurança desses dados.</p>
    <p>Vale ressaltar que nem a plataforma <i>Pixel Roads</i> pode decodificar os dados acima, sendo um processo totalmente anônimo e seguro.</p>
    <p>Também, não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
    <p>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.</p>
    <p>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados. </p>
    <p>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco através do e-mail suporte@adsplayx.com.br.</p>
    <p>Na medida do permitido pela lei aplicávelsuporte@adsplay.com.br, as categorias e tipos de dados pessoais coletados diretamente do Usuário, podem incluir, sem limitação, observados os princípios da finalidade, da adequação, da necessidade, do livre acesso, da qualidade dos dados, da transparência, da segurança, da prevenção, da não discriminação e da responsabilização e prestação de contas. A <i>Pixel Roads</i> pode ter acesso à:</p>
    <p>a) Sendo pessoa física: nome, estado civil, profissão, CPF, RG, data de nascimento, e-mail, número de telefone e celular, endereço, dados comerciais ou quaisquer outros Dados Pessoais fornecidos pelo Cliente para contratar o serviço fornecido pela <i>Pixel Roads</i>;</p>
    <p>b) Sendo pessoa jurídica: razão social, constituição social, CNPJ, RG, CPF e nome do representante legal, além dos demais dados fornecidos pelo Cliente para contratar, prospectar ou cancelar qualquer produto ou serviço fornecido pela <i>Pixel Roads</i>;</p>
    <p>c) Informações captadas de forma automática, como endereço de IP, local, data e hora, histórico de navegação, geolocalização, fonte de referência, tipo de navegador, duração da visita e páginas visitadas;</p>
    <p>Você será o único e exclusivamente responsável pela veracidade dos Dados Pessoais fornecidos à <i>Pixel Roads</i> no momento do seu cadastro. <i>Pixel Roads</i> não possui nenhuma responsabilidade pela veracidade dos dados fornecidos.</p>

    <h2>5. Coleta de dados durante navegação de serviços</h2>
    <p><strong>Resumo:</strong> Abordamos os principais pontos referente a coleta de dados.</p>
    <p>Enquanto você utiliza os nossos serviços, como visitante ou usuário, após seu consentimento desta Política, ocorrerão coletas de Dados de Navegação, que são as informações sobre as suas interações com o aplicativo, portal, redes sociais, entre outros, tais como: IP, histórico de pesquisas, histórico de navegação, datas e horários de acesso, recursos utilizados, a forma com que utiliza os produtos e serviços, problemas de sistemas do aplicativo/portal, entre outros. Esses dados são importantes para um melhor desempenho das funcionalidades disponibilizadas no aplicativo/portal a fim de possibilitar uma melhor experiência de navegação para você.</p>
    <p>Em alguns casos, podemos fazer essa coleta por meio de Cookies (ou tecnologia de coletas de dados similar), ferramentas importantes para o perfeito funcionamento de nossos serviços.</p>
    <p>Assim, você deve estar ciente de que, caso opte por recusar ou remover os Cookies, poderá ter a disponibilidade e a funcionalidade dos serviços da <i>Pixel Roads</i> afetadas. </p>

    <h2>6. Compromisso do Usuário</h2>
    <p><strong>Resumo:</strong> Detalhamos o compromisso do usuário com a veracidade dos dados e as medidas que, caso detectada uma fraude, podem ser tomadas pela <i>Pixel Roads</i>.</p>
    <p>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que a <i>Pixel Roads</i> oferece no site e com caráter enunciativo, mas não limitativo:</p>
    <p>A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</p>
    <p>B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou casas de apostas online, jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos. Também informamos que alguns temas sensíveis ou que ainda são passíveis de regulamentação mais ampla, pode ser bloqueados. Ex.: criptomoedas, Bitcoin ou NFTs.;</p>
    <p>C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do <i>Pixel Roads</i>, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</p>
    <p>D) Fornecer dados verdadeiros, corretos e atualizados. Caso o usuário forneça dados falsos, inválidos, incorretos ou de terceiros sem a devida autorização, a <i>Pixel Roads</i> se reserva ao direito de DESCADASTRAR IMEDIATAMENTE A CONTA E CANCELAR AS CAMPANHAS, sem qualquer notificação prévia ou prejuízo da adoção de eventuais medidas que entender cabíveis</p>
    <p>E) O USUÁRIO aceita que a <i>Pixel Roads</i> envie mensagens de e-mail e/ou de texto (SMS) para o celular cadastrado. Tais mensagens terão caráter informativo e referentes a comunicações específicas inerentes ao objeto deste documento, contendo informações sobre recursos e ofertas da <i>Pixel Roads</i>.</p>

    <h2>7. Responsabilidade pelo Conteúdo</h2>
    <p><strong>Resumo:</strong> A <i>Pixel Roads</i> não possui responsabilidade sobre do conteúdo dos anúncios e não permite anúncios que infrinjam </p>
    <p>Não é permitido a veiculação de anúncios que:</p>
    <p>(i) violem a lei, moral, bons costumes, propriedade intelectual, direitos à honra, vida privada, imagem, intimidade pessoal e familiar;</p>
    <p>(ii) estimulem a prática de condutas ilícitas ou contrárias à moral e aos bons costumes;</p>
    <p>(iii) incitem a prática de atos discriminatórios, seja em razão de sexo, raça, origem, religião, crenças, idade ou qualquer outra condição;</p>
    <p>(iv) coloquem à disposição ou possibilitem o acesso a mensagens, produtos ou serviços ilícitos, violentos ou degradantes;</p>
    <p>(v) enviem mensagens coletivas de e-mail (SPAM) ofertando produtos ou serviços de qualquer natureza, próprios ou de outrem, que não sejam de interesse dos destinatários ou que não tenham o expresso consentimento destes;</p>
    <p>(vi) induzam a um estado inaceitável de ansiedade ou temor;</p>
    <p>(vii) induzam ou incitem práticas perigosas, de risco ou nocivas para a saúde e para o equilíbrio psíquico;</p>
    <p>(viii) propaguem conteúdos falsos, ambíguos, inexatos, exagerados ou extemporâneos, de forma que possam induzir a erro sobre seu objeto ou sobre as intenções ou propósitos do comunicador;
    <p>(ix) violem o sigilo das comunicações;</p>
    <p>(x) constituam publicidade ilícita, enganosa ou desleal, ou que configurem concorrência desleal;</p>
    <p>(xi) veiculem, incitem ou estimulem a pedofilia;</p>
    <p>(xii) incorporem vírus ou outros elementos que possam danificar ou impedir o normal funcionamento de rede, sistema, documentos ou equipamentos informáticos (hardware e software) de terceiros;</p>
    <p>(xiii) obtenham ou tentem obter acesso não-autorizado a outros sistemas ou redes de computadores; e</p>
    <p>(xiv) reproduzam, vendam e distribuam produtos sem a devida autorização e o pagamento dos direitos autorais</p>
    <p>O USUÁRIO assume todos os ônus e responsabilidades decorrentes da má utilização da <i>Pixel Roads</i>, a responsabilidade pelos atos, danos e prejuízos que possam derivar da não observância das obrigações assumidas neste documento.</p>
    <h2>8. A Garantia sobre o Produto</h2>
    <p>A <i>Pixel Roads</i> responsabiliza-se somente pelas garantias e orientações fornecidas através de seu website e demais informações previstas nas descrições dos seus produtos e serviços ora comercializados.</p>
    <p>A <i>Pixel Roads</i>, por este instrumento, não reconhece a existência de qualquer garantia implícita ou explícita ao CLIENTE ou a terceiros relativamente aos seus produtos. A <i>Pixel Roads</i> também não reconhece a existência de quaisquer garantias implícitas, incluindo, mas não limita as garantias relativas a direitos, comercialização ou conveniência para fins específicos para os produtos.</p>

    <h2>9. Direitos Autorais </h2>
    <p><strong>Resumo:</strong> Não é permitida a reprodução ou armazenamento público ou privado sem a autorização prévia por escrito da <i>Pixel Roads</i>.</p>
    <p>O CLIENTE não é autorizado, em nenhuma hipótese, a utilizar quaisquer dos materiais deste website ou o próprio website, para fins comerciais, sem que a <i>Pixel Roads</i> lhe conceda uma licença específica para esse fim.<p>
    <p>Nenhuma parte deste website pode ser reproduzida e/ou armazenada em qualquer outro website ou incluída em qualquer outro sistema ou serviço de recuperação eletrônico público ou privado, sem a autorização prévia por escrito da <i>Pixel Roads</i>.<p>
    <p>Qualquer reprodução de uma obra autoral de um autor, seja ela literária, artística ou científica, sem a sua devida autorização, é considerada como uma cópia não autorizada.<p>
    <p>Quaisquer direitos não expressamente previstos neste Termos e Condições de Uso são reservados à <i>Pixel Roads</i>.<p>

    <h2>10. Confidencialidade</h2>
    <p>Para os fins deste Termo, “Informação Confidencial” é considerada toda e qualquer informação transitada entre as Partes, seja verbal, escrita, em formato eletrônico ou por qualquer outra forma de transmissão que, seja por suas características essenciais, ou pelas circunstâncias de fato, não podem se tornar públicas e devem ser mantidas em confidencialidade e segredo.</p>
    <p>A Informação Confidencial deve ser usada pela <i>Pixel Roads</i> apenas para atingir os propósitos deste Termo e nos limites necessários para atingir tais objetivos, e o CLIENTE, neste caso, deverá informar sobre a sensibilidade e natureza da Informação Confidencial a todos que a receberem.</p>
    <p>Fica expressamente acordado entre as Partes que, durante o prazo de vigência deste Termo e mesmo após seu término, as Partes se obrigam a manter em sigilo e a não disponibilizar para quaisquer terceiros todos e quaisquer termos, existência e condições do presente Termo, bem como qualquer informação ou documento a que vierem a ter acesso em virtude do presente Termo. As informações confidenciais serão consideradas segredos de negócio para os fins e efeitos do Artigo 195, Inciso XI, da Lei nº 9.279/96.</p>

    <h2>11. Informações de contato e suporte</h2>
    <p>O CLIENTE dispõe de canal para sanar suas dúvidas, solucionar eventuais solicitações ou reclamações atinentes ao seu pedido ou outras informações disponibilizadas no website. O FALE CONOSCO poderá ser acionado por meio de envio de e-mail para suporte@adsplayx.com.br.</p>

    <h2>12. Vigência e Modificações</h2>
    <p>O presente instrumento tem validade indeterminada e a <i>Pixel Roads</i> poderá revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atualizada destes termos de serviço.</p>

    <h2>13. Lei aplicável</h2>
    <p>Estes termos e condições são regidos e interpretados de acordo com as leis da <i>Pixel Roads</i> e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais da localidade da empresa - São Paulo, SP, Brasil.</p>

    <h1 class="font-size-large text-color5">
      <span>Termos de Uso e Política de Privacidade AGÊNCIAS PARCEIRAS</span>
    </h1>
    <p><strong>Resumo:</strong> Neste documento trataremos das regras e condições de uso do software <i>Pixel Roads</i> para agências de mídia, ou seja, os intermediários entre a plataforma e o cliente final, que neste documento chamaremos de anunciantes.</p>

    <p>A agência parceira tem como obrigação o cumprimento de todas as regras dispostas sobre a plataforma, bem como conhecer as boas práticas do mercado programático e operar a ferramenta sem que ocorra qualquer tipo de ofensa, preconceito ou crime. Também é obrigação da agência parceira fornecer informações corretas e fidedignas, mantendo as mesmas sempre atualizadas.<p>

    <p>Por se tratar de uma parceria, como contrapartida a <i>Pixel Roads</i> oferece diversos benefícios e vantagens, podendo ser bônus financeiros, brindes ou descontos. A <i>Pixel Roads</i> se compromete a fazer ações pontuais e benéficas para os parceiros, e é de livre vontade da plataforma aumentar ou reduzir as bonificações.<p>

    <p>As parcerias com a <i>Pixel Roads</i> são classificadas em níveis, variando de acordo com o volume mensal investido na plataforma. Os níveis são:<p>

    <ul>
      <li>STARTER – até  R$1.500,00 mês</li>
      <li>PLAY– R$6.000,00 mês</li>
      <li>ACCELERATED  – R$15.000,00 mês</li>
      <li>FULL – a partir de R15.000,00 mês</li>
    </ul>

    <p>A média mensal de investimento dos últimos três meses é considerada para definir a classificação atualizada da agência parceira na plataforma.</p>

    <h2>FINANCEIRO:</h2>
    <p><strong>Cashback sobre o valor incluído de crédito:</strong></p>
    <p>Nível: todos</p>

    <p>O parceiro receberá uma porcentagem, que chamaremos de CASHBACK, referente aos créditos incluídos na plataforma, isto é, revendas dos campanhas (PIs), fizer a seus clientes. A comissão será variável, podendo ocorrer campanhas com maiores ou menores porcentagens a critério da <i>Pixel Roads</i>, não sendo gerados cashbacks para valores inseridos a título de compensações, bonificações ou premiações. </p>

    <p>Os pagamentos serão repassados ao final de cada mês, respeitando 30 dias após o investimento realizado na plataforma, através do nosso parceiro intermediador de pagamento Pagar.me, em uma carteira digital.</p>

    <h2>EDUCAÇÃO:</h2>

    <p><strong>ADSPLAY  Educação:</strong></p>
    <p>Nível: Todos</p>
    <p>Acreditamos na educação e desenvolvimento profissional de nossos parceiros, e por isso todos os parceiros da <i>Pixel Roads</i> possuem acesso gratuito aos cursos da ADSPLAY Educação, que são aulas gravadas pelo nosso time de especialistas nos mais diversos temas relacionados ao mercado de mídia, marketing digital, performance, mídia programática e digital analytics.</p>

    <p><strong>Treinamentos em grupo</strong></p>
    <p>Nível: Starter e Play</p>
    <p>Ao longo do ano temos diversos treinamentos e eventos disponibilizado para nossos parcerios, esse é o momento de estar atualizado nas novidades do mercado e conhecer novas oportunidades de receita. Os parceiros no nível Starter tem acesso aos eventos online abertos. Já no nível Play, podem ter acesso a eventos online fechados com público reduzido e seleto.</p>
    <p>Nível: Accelerate e Full Online</p>
    <p>Além de acesso aos conteúdos online abertos e exclusivos dos níveis anteriores, parceiros nos níveis Accelerate e Full tem acesso a eventos presenciais com especialistas e time <i>Pixel Roads</i> para dividir melhores práticas, tirar dúvidas e se preparar para datas importantes do mercado. </p>
    <p><small>*Os custos de deslocamento e hospedagem são de responsabilidade da agência parceira.</small></p>

    <p><strong>Consultoria especializada de Business</strong></p>
    <p>Nível:  Full</p>
    <p>Acreditamos em parcerias e por isso, para os nossos parceiros com melhor desempenho oferecemos consultorias de especialistas em desenvolvimento de negócios, focados em aconselhar os responsáveis por decisões estratégicas da equipe do parceiro.</p>
    <p>Essas consultorias permitem acesso aos principais executivos da ADSPLAY para apoiar a agência em conselhos que possam melhorar pontos como captação de clientes, operações, marketing, branding e operações.</p>

    <p><strong>Partner Executive Concil</strong></p>
    <p>Nível: Full</p>
    <p>A <i>Pixel Roads</i> nasceu de um sonho de entregar a mídia programática para mais pessoas, e sabemos que isso só é possível graças aos nossos parceiros. Pensando nisso, as agências terão direito de participar de encontros exclusivos (online e presencial*) para parceiros com os especialistas e diretores da ADSPLAY , onde poderão apresentar suas necessidades e participar da construção da plataforma, e suas melhorias, de maneira conjunta.</p>
    <p>O foco deste comitê é a geração de novos negócios, ajustes de backlog da plataforma e alinhamento de estratégias entre a plataforma e seus  parceiros. </p>
    <p><small>*Os custos de deslocamento e hospedagem são de responsabilidade da agência parceira.</small></p>

    <h2>MARKETING E VENDAS:</h2>

    <p><strong>Direito de usar logo de parceiro ADSPLAY  </strong></p>
    <p>Nível: Todos</p>
    <p>Todos os parceiros da <i>Pixel Roads</i> possuem o direito de utilizar o selo da parceria em seus sites, materiais ou páginas de redes sociais.<br/>
    Reforçamos que o bom uso é de responsabilidade do parceiro. </p>

    <p><strong>Elegível para utilizar materiais whitelabel</strong></p>
    <p>Nível: Todos</p>
    <p>Em nossa plataforma os resultados de suas campanhas são transparentes, e todos os relatórios são pensados em oferecer a melhor experiência para o seu cliente, por isso, fornecemos a opção de inclusão de suas cores e logo para extração de relatórios de performance.</p>

    <p><strong>Elegível para estudos de caso de clientes</strong></p>
    <p>Nível: Accelerated e Full</p>
    <p>Na <i>Pixel Roads</i> trabalhamos com resultados reais, e por isso, oferecemos a possibilidade do parceiro ser convidado a protagonizar um estudo de caso de um cliente ou agência a ser publicado no site e/ou blog de agência da <i>Pixel Roads</i>. </p>

    <p><strong>Desenvolver materiais de co-marketing exclusivos</strong></p>
    <p>Nível: Play, Accelerated e Full</p>
    <p>Somos muito focados em resultados e novos negócios, e gostaríamos de levar essa cultura para nossos parceiros. Ao longo do ano, algumas agências serão escolhidas para para a produção de materiais em parceria com a <i>Pixel Roads</i> focados na geração de novas oportunidades.</p>

    <p><strong>Elegível para receber oportunidades qualificadas de vendas</strong></p>
    <p>Nível: Full</p>
    <p>Confiamos e buscamos ajudar no desenvolvimento dos nossos parceiros, e por isso anunciantes com potencial de otimização podem ser direcionados para parceiros que tenham um bom resultado em métricas e forneçam um bom atendimento.</p>

    <h2>COMUNIDADE:</h2>

    <p><strong>Elegível para o preview de recursos beta da <i>Pixel Roads</i></strong></p>
    <p>Nível: Full</p>
    <p>Estamos sempre evoluindo e nada melhor que quem utiliza a plataforma para avaliá-la, por isso a agência parceira poderá ser convidada a utilizar, testar e dar feedback sobre as novas funcionalidades da <i>Pixel Roads</i>, antes de seu lançamento oficial.</p>

    <p><strong>Acesso a comunidade de parceiros</strong></p>
    <p>Nível: todos</p>
    <p>Focados em colaboração, networking, integração, disponibilizamos o acesso a comunidade <i>Pixel Roads</i>. Um ambiente colaborativo focado em otimização e melhores resultados. </p>

    <p><strong>Elegível a participar de premiações Agência <i>Pixel Roads</i></strong></p>
    <p>Nível: Accelerated e Full</p>
    <p>Reconhecemos nossos parceiros e por isso, ao longo do ano oferecemos premiações para os melhores. </p>

    <p>O cadastro na plataforma deverá ser realizado pela própria agência parceira, não sendo a <i>Pixel Roads</i> responsável por quaisquer divergências que possam ocorrer. Reforçamos que o cadastro é gratuito e que ao fazê-lo está de acordo em receber as comunicações referente a atualizações e promoções. </p>

    <p>A <i>Pixel Roads</i> se garante ao direito de realizar o upgrade e o downgrade de categorias, seguindo a regra de receita média mensal dos últimos três meses. Sempre que mudanças forem feitas, a <i>Pixel Roads</i> se compromete e avisar, com antecedência, através dos meios de contato cadastrados pelo Cliente. </p>

    <p>O presente instrumento tem validade indeterminada e a <i>Pixel Roads</i> poderá revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atualizada destes termos de serviço. </p>

    <p>Estes termos e condições são regidos e interpretados de acordo com as leis da <i>Pixel Roads</i> e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais da localidade da empresa - São Paulo, SP, Brasil. </p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Termos de Uso e Política de Privacidade'
  }
}
</script>
